// Typography styles here

h1 {
  font-size: 40px !important;
  line-height: 48px !important;
  padding-bottom: 20px !important;
  font-family: $font-family-sans-serif2 !important;
  margin-bottom : auto !important;
  font-weight : normal !important;
}
h2 {
  font-size: 28px !important;
  line-height: 36px !important;
  padding-bottom: 16px !important;
  font-family: $font-family-sans-serif2 !important;
  margin-bottom : auto !important;
  font-weight : normal !important;
}
h3 {
  font-size: 20px !important;
  line-height: 28px !important;
  padding-bottom: 10px !important;
  font-family: $font-family-sans-serif2 !important;
  margin-bottom : auto !important;
  font-weight : normal !important;
}
h4 {
  font-size: 16px !important;
  line-height: 20px !important;
  padding-bottom: 10px !important;
  font-family: $font-family-sans-serif2 !important;
  margin-bottom : auto !important;
  font-weight : normal !important;
}
p {
  line-height: 24px !important;
  font-family: $font-family-sans-serif !important;
}
small {
  font-size: 14px !important;
  line-height: 20px !important;
}

.are-you-pasti-font {
  font-size: 45px;
  line-height: 48px;
  padding-bottom: 20px;
  font-family: $font-family-sans-serif2;
  margin-bottom : auto;
  font-weight : bold;
}

.spacer {
  margin:0;
  padding:0;
  height:250px;
}

.white-font {
  p {
    color: $pasti-white;
  }
  ul {
    color: $pasti-white;
  }
  h1 {
    color: $pasti-white;
  }
}

.has-vertically-aligned-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.example-typography-normal {
  p {
    line-height: 24px;
    padding: 16px 0;
    font-size: 16px;
    font-family-sans-serif: 'Roboto', sans-serif;
  }
}

.example-typography-heading table td {
  vertical-align: middle !important;
}