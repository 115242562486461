// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito|Roboto');

// Icons
@import "node_modules/font-awesome/scss/font-awesome";

// Bulma
@import "~bulma";

// Variables
@import "base/variables";

// Pasti Marketing Theme
@import "custom-theme";

// Typography
@import "base/typography";

// Background
@import "base/background";

// Animation
@import "base/animation";

// Mainpage
@import "pages/mainpage";

// Package
@import "pages/package";

// Insurance
@import "pages/insurance";

// Client
@import "pages/client";

// Testi
@import "pages/testi";

// About Us
@import "pages/about-us";

// Fleet Management 
@import "pages/fleet-management";

// Header
@import "layouts/header";

// Footer
@import "layouts/footer";

// Forms
@import "layouts/forms";

// Buttons
@import "components/buttons";

// Cards
@import "components/cards";

// Modal
@import "components/modal";

// Table
@import "components/table";




