////////////////////////////////////////////////////////////////////////////////////////////////
// Refer Bulma package to view all variables [/node_modules/bulma/sass/utilities/variables.sass]
// Grid
$grid-columns:              12;
$grid-gutter-width:         20px;
$grid-float-breakpoint:     768px;

// Body
$body-bg: #ffffff;

// Border Radius
$border-radius-base: 4px !default;

$screen-sm-min: 768px !default;

// Colors
$pasti-white: #ffffff;
$pasti-light-blue: #f8fcfe;
$pasti-light-grey: #efefef;
$pasti-dark-blue: #081939;
$pasti-link-blue: #1395f7;
$pasti-link-dark-blue: #1B5cdf;
$pasti-darker-blue: #1395E7;
$pasti-btn-green: #3faa04;
$pasti-btn-hover-green: #1f7f02;
$pasti-btn-blue: #1b5cdf;
$pasti-btn-hover-blue: #1340d5;
$pasti-btn-grey: #828384;
$pasti-btn-hover-grey: #5c5e5f;


$primary-strong-red: #c72027;
$primary-dark-blue: #081939;
$primary-strong-blue: #008fd1;

$almost-black-grey: #333333;
$very-dark-grey: #666666;
$dark-grey: #999999;
$light-grey: #efefef;
$very-light-grey: #eeeeee;
$almost-white-grey: #fafafa;

$vivid-green: #5cb85c;
$vivid-yellow: #f5c70e;
$vivid-red: #d9534f;
$light-green: #d9eece;
$light-yellow: #fef6c2;
$light-blue: #f8fcfe;
$light-red: #fde0e0;

$white: #ffffff;
$black: #000000;

// Borders
$list-group-border: $light-grey;
$navbar-default-border: $light-grey;
$panel-default-border: $light-grey;
$panel-inner-border: $light-grey;
$table-border-color: $light-grey;
$input-border: $light-grey;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$fa-font-path: "../fonts" !default;
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-sans-serif2: 'Nunito', sans-serif;
$font-size-base: 16px;
$line-height-base: 1.6;
$text-color: #666666;
$headings-color: #333333;
$headings-font-weight: 700;
$headings-line-height: 20px;
$font-size-h1: 24px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 14px;
$font-size-h6: 12px;
$link-color: #c72027;

// Navbar
$navbar-default-bg: #fff;

// Buttons
// $btn-default-color: $text-color;
$padding-large-vertical: 14px;
$padding-large-horizontal: 20px;
$font-size-large: 18px;
// $line-height-large: 16px;
$padding-base-vertical: 8px;
$padding-base-horizontal: 16px;
$font-size-base: 16px;
// $line-height-base: 16px;
$padding-small-vertical: 8px;
$padding-small-horizontal: 16px;
$font-size-small: $font-size-base;
// $line-height-small: 16px;
$padding-xs-vertical: 4px;
$padding-xs-horizontal: 12px;
$font-size-small: 14px;
// $line-height-small: 16px;

// Panels
$panel-default-heading-bg: #fff;

// Pagination
$pagination-color: $text-color;
$pagination-bg: $white;
$padding-base-vertical: 4px;
$padding-base-horizontal: 20px;
$pagination-hover-color: $light-grey;

// Carousel
$carousel-indicator-border-color: transparent;
$carousel-indicator-active-bg: $primary-dark-blue;


// Modals
$modal-title-padding: 24px;
$modal-inner-padding: 24px;

// Form
$input-height-base: 46px;
$input-color: $almost-black-grey;
$input-color-placeholder: $dark-grey;
