// Cards styles here


.card {
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.03);
  padding: 24px;
  &.stacked {
    background-color: $very-light-grey;
    border-top: 3px solid rgba(0,0,0,0.2);
  }

}

.card-form {
  border-radius: 0px;
  border: 0px solid rgba(0,0,0,0.03);
  a {
    color: $pasti-link-blue;
    word-wrap: break-word;
  }


  &.demo {
    padding: 20px;
    a {
      color: $pasti-link-blue;
    }

  }

  &.flat {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    padding: 20px;
    a {
      color: $pasti-link-blue;
    }
  }

  &.privacy {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    padding: 80px;

    a {
      color: $pasti-link-blue;
      word-wrap: break-word;
    }
  }

  &.stacked {
    background-color: $very-light-grey;
    border-top: 3px solid rgba(0,0,0,0.2);
  }
  &.sent {
    min-height: 370px;
  }
  &.package {

    &:hover {
      transform: scale(1.05,1.05);
    }

    transition: all 0.3s ease-in-out;
    a {
      color: $pasti-white;
      word-wrap: break-word;
    }
    padding: 16px;
    text-align: center;
    p {
      padding-top: 30px;
      color: $almost-black-grey;
      font-weight: bold;
    }
    .btn {
      margin-top: 30px;
    }
  }
  &.packageselect {

    &:hover {
      transform: scale(1.05,1.05);
    }

    transition: all 0.5s;
    border-style                :   solid;
    border-width                :   8px;
    border-color                :   $pasti-btn-green;
    padding: 8px;
    text-align: center;
    p {
      padding-top: 30px;
      color: $almost-black-grey;
      font-weight: bold;
    }
    .btn {
      margin-top: 30px;
    }
  }
  &.sentemail {
    padding: 32px;
    img {
      height: 100px;
      width: 100px;
    }
  }
  &.success {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    padding: 20px;
    a {
      color: $pasti-link-blue;
    }
  }

}

/* tablet potrait & above */
@media (max-width: 768px) {

  .card-form {
    &.flat {
      padding: 32px;
    }
    &.package {
      margin-bottom: 60px;
    }
    &.packageselect {
      margin-bottom: 60px;
    }
    &.privacy {
      padding: 20px;
    }
    &.success {
      padding: 0;
    }
    &.demo {
      padding: 5px;
    }
  }

}

@media(min-width:768px){
  .card-form {
    margin-bottom: 0;
  }
}

a .fa {
  margin-right: 6px !important;
}
