// Footer styles here

.page-footer-top {
  padding: 80px 0;
  a {
    color: $pasti-darker-blue !important;
    text-decoration: none;
  }
  h3 {
    color: $pasti-white;
    margin-top: 0px;
  }
}

.page-footer-middle {
  padding: 20px 0;
  p {
    font-size: 9px;
    font-weight: bold;
    margin: auto;
    padding: auto;
  }
}

/* tablet potrait & above */
@media (max-width: 768px) {
  .page-logo-footer {
    padding-bottom: 30px;
  }
}

.page-footer-bottom {
  height: 30px;
}

.page-bottom-cta {
  padding: 60px 0;

  .btn {
    margin-left: 0px;
  }
  p {
    padding-top: 10px;
    padding-left: 50px;
    color: $pasti-white;
  }
}

.page-footer-address{
  a {
    color: $pasti-white !important;
  }
}

/* tablet potrait & above */
@media (max-width: 768px) {

  .page-footer-address{
    padding-top:40px;
  }

  .page-footer-button{
    padding-bottom: 40px;
  }
  .mobile-text-center {
    text-align: center
  }

}