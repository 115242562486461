// Package styles here

.section-layout-package {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 80px;
  padding-top: 80px;
  text-align: center;
  h1 {
    color: $pasti-white;
  }
  h2 {
    color: $pasti-white;
  }
  p {
    padding-bottom: 30px;
    color: $pasti-white;
  }
}

@media screen and (max-width: $tablet) {

  .section-layout-package {
  padding-left: 0;
  padding-right:0;
  }
  .package-content-grey {
    padding-left: 30px !important;
  }
  .package-content-white {
    padding-left: 30px !important;
  }
  .section-layout-ourteam {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .white-myeg {
    border-radius: 50%;
    width: 270px !important;
    height: 270px !important;
    background: white;
    position: relative;
    margin: auto;
  }

}

.package-content-grey {
  background-color: $pasti-light-grey;
  color: $almost-black-grey;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  text-align: left;
  padding-left: 40px;
}

.package-content-white {
  color: $almost-black-grey;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  text-align: left;
  padding-left: 40px;
}