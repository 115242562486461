// Mainpage styles here

.protect-monitor {
  padding-top: 80px;
  padding-bottom: 80px;

  img {
    padding-bottom: 30px;
  }
  .right-text {
    text-align:right;
  }
  .image-left {
    min-height: 400px;
    position: relative;

    img {
      height: 100px;
      width: 100px;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 85%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }
  }

  .left-text {
    padding-left: 2rem;
  }

  .right-text {
    padding-right: 2rem;
  }

  .image-center {

    img {
      padding: 60px;
    }
  }

  .image-right {
    min-height: 400px;
    position: relative;

    img {
      height: 100px;
      width: 100px;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 25%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }
  }
  .image-right-mobile {
    display: none;
  }
  .image-left-mobile {
    display: none;
  }
}


/* tablet potrait & below */
@media (max-width: 768px) {

  .image-left {
    img {
      height: 200px !important;
      width: 200px !important;
    }
  }
  .image-right {
    img {
      height: 200px !important;
      width: 200px !important;
    }
  }

}