// Background styles here

.bg-0 {
  background-color: $pasti-white;
  position: relative;
}

.bg-1 {
  background: #020e46;
  background: -moz-linear-gradient(top, #020e46 0%, #076cb9 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #020e46), color-stop(100%, #076cb9));
  background: -webkit-linear-gradient(top, #020e46 0%, #076cb9 100%);
  background: -o-linear-gradient(top, #020e46 0%, #076cb9 100%);
  background: -ms-linear-gradient(top, #020e46 0%, #076cb9 100%);
  background: linear-gradient(to bottom, #020e46 0%, #076cb9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#020e46', endColorstr='#076cb9', GradientType=0 );
}


.bg-2 {
  background-color: $pasti-light-blue;
}

.bg-2-testi {
  background-color: $pasti-light-blue;
  border: 1px solid #efefef;
}

.bg-3 {
  background-color: $pasti-light-grey;
}

.bg-4 {
  background-color: $pasti-dark-blue;
}

.bg-5 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#142b57+0,1b3a74+100 */
  background: #142b57; /* Old browsers */
  background: -moz-linear-gradient(left, #142b57 0%, #1b3a74 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #142b57 0%,#1b3a74 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #142b57 0%,#1b3a74 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#142b57', endColorstr='#1b3a74',GradientType=1 ); /* IE6-9 */
}

.bg-1 {
  width: 100%;
  background-image: linear-gradient(to bottom, #020e46 0%, #076cb9 100%);
  background-position: center;
  background-repeat: no-repeat;
}

.imagebackground {
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
}


.bg-1-image-mountain {
  width: 100%;
  background-image: url(../../images/mountain.svg), linear-gradient(to bottom, #020e46 0%, #076cb9 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.bg-1-image-fleet {
  width: 100%;
  background-image: url(../../images/mini-grid2.svg), linear-gradient(to bottom, #020e46 0%, #076cb9 100%);
  background-position: center;
  background-repeat: background-repeat;
  background-size: contain;
}

.bg-1-image-myeg {
  width: 100%;
  background-image: url(../../images/map-malaysia.svg), linear-gradient(to bottom, #020e46 0%, #076cb9 100%);
  background-position: center;
  background-size: cover;
  background-repeat: repeat-x;
}


@media screen and (max-width: 768px) {
  .bg-1-image-fleet {
    background-size: cover;
  }

  .imagebackground  {
    background-position: bottom;
    height: 100%;
  }

}

.link-1 {
  background-color: $pasti-link-blue;
}

.link-2 {
  background-color: $pasti-link-dark-blue;
}

.bg-0 {
  background-color: $pasti-white;
}

.bg-1 {
  background-color: #020e46;
}

.bg-2 {
  background-color: $pasti-light-blue;
}

.bg-4 {
  background-color: $pasti-dark-blue;
  color: $white;
}

.link-1 {
  background-color: $pasti-link-blue;
  color: $white;
}

.link-2 {
  background-color: $pasti-link-dark-blue;
  color: $white;
}

.bg-almost-black-grey {
  background-color: $almost-black-grey;
  color: $white;
}

.bg-very-dark-grey {
  background-color: $very-dark-grey;
  color: $white;
}

.bg-dark-grey {
  background-color: $dark-grey;
  color: $white;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  .title {
    color: $white;
  }
}

.bg-light-grey {
  background-color: $light-grey;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
}

.bg-very-light-grey {
  background-color: $very-light-grey;
}

.bg-almost-white-grey {
  background-color: $almost-white-grey;
}

.bg-vivid-green {
  background-color: $vivid-green;
  color: $white;
}

.bg-vivid-yellow {
  background-color: $vivid-yellow;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
}

.bg-vivid-red {
  background-color: $vivid-red;
  color: $white;
}

.bg-light-green {
  background-color: $light-green;
}

.bg-light-yellow {
  background-color: $light-yellow;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-light-red {
  background-color: $light-red;
}

.bg-strong-red, .btn-strong-red {
  background-color: $primary-strong-red;
  color: $white;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  .title, a {
    color: $white !important;
  }
}

.bg-dark-blue, .btn-dark-blue {
  background-color: $primary-dark-blue;
  color: $white;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  .title {
    color: $white;
  }
}

.bg-strong-blue, .btn-strong-blue {
  background-color: $primary-strong-blue;
  color: $white;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  .title {
    color: $white;
  }
}
