// Testi styles here

.testimonial {
  padding: 60px 0;
  margin-bottom: 60px;
  margin-top: 60px;

  img {
    border-radius: 50%;
    padding: 4px;
    border: 2px solid #efefef;
  }
}

// Chat bubble stars here

/* default mobile first */
.testimonial {
  display: flex;
  flex-wrap: wrap;

  padding: 20px;
  align-items: center;
}

.bubble {
  border: 2px solid #efefef;
  border-radius: 7px;
  flex-basis: 100%;
  padding: 20px;
  position: relative;
  background-color: #fff
}

.bubble::after {
  content: ' ';
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #efefef;
  border-right: 2px solid #efefef;
  position: absolute;
  bottom: -11px;
  left: 35px;
  transform: rotate(45deg);
  background-color: #fff;
}

.display-picture {
  margin-top: 20px;
}

.designation {
  flex: 1;
  margin-top: 25px;
}

.designation p {
  margin: 0
}

.designation-2 {
  display: none;
  margin-top: 20px;
}

.designation-2 p {
  margin: 0;
}

/* tablet potrait & below */
@media (min-width: 768px) {
  .display-picture {
    order: 1;
  }

  .bubble {
    order: 2;
    flex: 1;
  }

  .bubble::after {
    left: -18px;
    top: 50%;
    border-left: 2px solid #efefef;
    border-right: none;
    transform: rotate(45deg) translateY(-50%);
  }

  .designation {
    display: none;
  }

  .designation-2 {
    display: block;
  }
}
// End of chat bubbles

.navbar-default {
  border-color: #fff;
}

.navbar-collapse {
  float: right;
}

// End chat bubble