// Fleet Management styles here

.areyoupasti {
  padding: 80px 0;
}


@media screen and (max-width: $screen-sm-min) {

  .areyoupasti {
    padding: 0;
  }

}

.section-layout-iconlist {
  padding-left: 0;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  p {
    color: $pasti-white;
  }
  h3{
    color: $pasti-white;
  }
  li {
    list-style-type: none;
  }
  img {
    border-radius: 50%;
    margin:auto;
  }
}

@media screen and (max-width: $screen-sm-min) {


  .iconlist-image {
    float: left;
    // width: 80px;
    // height: 80px;
    height: auto;
    width: 25%;
  }

  .iconlist-text {
    text-align: left;
    float: left;
  }

  .section-layout-iconlist {
    padding: 20px 0;

    li {
      clear: both;
      padding-bottom: 30px;
      padding-top: 30px;
      overflow: hidden;
    }
  }

}

.fleet-monitor {
  background: $pasti-white no-repeat 50% 50%;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  padding: 10%;
}

@media screen and (max-width: $screen-sm-min) {

  .fleet-monitor {
    width : 270px;
    height : 270px;
  }
  .section-layout-half {
    p {
      text-align: center;
    }
    h1 {
      text-align: center;
    }
  }

}

.iconlist-image {
  background: $pasti-white no-repeat 50% 50%;
  border-radius: 50%;
  width: 130px;
  height: 130px;
}