
body {
  overflow-y: hidden;
  overflow-x: hidden;
}

@media screen and (min-width: $tablet) {
  .container {
    width: 960px;
  }
}

@media screen and (min-width: $desktop) {
  .container {
    width: 1160px;
  }
}

.wrapper {
  padding: 0 2rem;
}

@media screen and (max-width: $tablet) {
  .wrapper {
    padding: 0 1rem;
  }
}

.full-centered {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

.half-image-text {
  padding-top: 80px;
  padding-bottom: 80px;
  // border-color: $pasti-light-grey;
  // border-style: solid;
  // border-width: 1px;
}

.half-text-image {
  padding-top: 80px;
  padding-bottom: 80px;


  img {
    padding-bottom: 30px;
  }
}

.section-layout-center {
  padding-top: 80px;
  padding-bottom: 80px;

  h1 {
    color: $pasti-white;
    margin-bottom: 12px;
  }
  img {
    margin-bottom: 32px;
  }
  .btn {
    margin-bottom: 32px;
  }
}

.section-layout-half {
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 32px;

  h1 {
    color: $pasti-white;
    margin-bottom: 12px;
  }
  img {
    border-radius: 50%;
  }
  p {
    color: $pasti-white;
  }
}


.section-layout-half-form {
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 32px;

  p {
    color: $pasti-white;
  }
  h1 {
    color: $pasti-white;
    margin-bottom: 12px;
  }
  ul li {
    color: $pasti-white;
  }
}

.section-layout-feedback {
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-layout-half-form-text{
  margin-bottom: 60px;
  ul {
      list-style-type: disc;
  }
}

.section-layout-full-form {
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 32px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
