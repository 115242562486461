// Header styles here

.page-header-top {
  height: 30px;
  padding-right: 30px;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  .btn-top {
    line-height: 30px;
    margin-left: 10px;
    a {
      color: $pasti-link-dark-blue!important;
      padding-left: 40px;
    }
  }

}

.page-header-bottom {
  padding-top: 20px;
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  .btn-bottom {
    line-height: 20px;
    margin-left: 10px;
    color: $pasti-white!important;
    &:hover {
      background-color: $pasti-btn-blue!important;
      color: $pasti-white!important;
    }
  }
  a {
    color: #333333 !important;
    text-decoration: none;
  }
  a:hover {
    color: $pasti-link-dark-blue!important;
    text-decoration: none;
    background-color: $pasti-light-blue!important;
  }
  .active a {
    color: $pasti-link-dark-blue!important;
  }

}

.navbar-end {
  list-style-type: none;
}

.page-footer-top p {
  margin: 0 0 12.5px;
}


.navbar-toggle {
  margin-top: 10px;
}


/* tablet potrait & above */
@media (max-width: 768px) {

  .page-header-bottom {
      padding: 0;
      margin: 0;

    img {
      width : 80px;
      height : 25px;
    }
  }

  .full-form {
    padding-top : 25px;
  }

}


@media screen and (max-width: $tablet) {
  .navbar-collapse {
    float: left;
    text-align: left;
    border-top: none;
    width: 100%;
    .active a {
      color: $almost-black-grey !important;
      background-color: white !important;
    }
    margin-left: 15px;
    margin-top: 10px;
  }
  .collapsible-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    top: 0;

  }
  .navbar.is-active {
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
  }
  .navbar-menu {
    box-shadow:none;
  }
  .menu-link-bottom {
    background-color: $pasti-btn-green !important;
    box-shadow: inset 0 -4px 0 #1F7F02 !important;
    position: fixed !important;
    bottom: 0;
    width: 100%;
    left: 0;
    padding-top: 15px !important;
    padding-bottom: 40px !important;
    margin: 0 !important;
  }
  .navbar-burger {
    margin-right: 10px;
  }
  .navbar-brand {
    margin-top: 10px;
  }
  .burger.is-active span {
    background-color: $dark-grey;
    margin-right: 10px;
  }
}

.navbar-item img {
  max-height: 3rem;
}