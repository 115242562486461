// Buttons styles here

.example-button-row, .example-background {
  margin-bottom: 20px;
}

.btn {
  border: none;
  color: $white !important;
  // padding-left: 16px;
  // padding-right: 16px;
  font-weight: bold;
  border-radius: 0px;
  font-size: 1rem;
  height: 50px;

  &:focus {
    opacity: 0.8;
  }
  &:active, &.active {
    box-shadow: none !important;
  }
  &:disabled, &.disabled {
    box-shadow: none !important;
  }

  &.btn-small {
    background-color: $pasti-btn-green !important;
    box-shadow: inset 0 -4px 0 #1F7F02;
    line-height: 20px;
  }
  &.btn-normal {
    background-color: $pasti-btn-green !important;
    box-shadow: inset 0 -4px 0 #1F7F02;
    line-height: 30px;
  }
  &.btn-large {
    background-color: $pasti-btn-green !important;
    box-shadow: inset 0 -4px 0 #1F7F02;
    line-height: 28px;
  }

  &.btn-p0 {
    background-color: $pasti-btn-green !important;
    box-shadow: inset 0 -4px 0 #1F7F02;
  }
  &.btn-p1 {
    background-color: $pasti-btn-blue;
    box-shadow: inset 0 -4px 0 #1340D5;
  }
  &.btn-p2 {
    background-color: $pasti-btn-grey;
    box-shadow: inset 0 -4px 0 #5C5E5F;
  }
  &.btn-dark-blue {
    background-color: $primary-dark-blue;
    box-shadow: inset 0 -4px 0 #21232E;
  }

  &.btn-p0-hover {
    background-color: #1F7F02 !important;
    box-shadow: inset 0 -4px 0 #1F7F02;
  }
  &.btn-p1-hover {
    background-color: #1340D5;
    box-shadow: inset 0 -4px 0 #1340D5;
  }
  &.btn-p2-hover {
    background-color: #5C5E5F;
    box-shadow: inset 0 -4px 0 #5C5E5F;
  }
  &.btn-dark-blue {
    background-color: $primary-dark-blue;
    box-shadow: inset 0 -4px 0 #21232E;
  }

  &.btn-header {
    background-color: $white;
    color: $headings-color !important;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    padding: 14px;
    text-align: left;
    width: 100%;
    font-size: 20px;
    strong {
      color: $primary-strong-red;
      font-weight: 700;
    }
    &:hover,
    &:focus,
    &:active, &.active {
      border: 1px solid $primary-strong-red;
      box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    }
  }

  &.btn-menu {
    background-color: $almost-white-grey;
    border: 1px solid $light-grey;
    // box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    color: $headings-color !important;
    padding: 14px;
    text-align: left;
    width: 100%;
  }
}

.button-large {
  background-color: #3faa04 !important;
  box-shadow: inset 0 -4px 0 #1F7F02;
  height: auto !important;
  font-size: 18px;
  line-height: 40px !important;
}

.button-normal {
  background-color: #3faa04 !important;
  box-shadow: inset 0 -4px 0 #1F7F02;
  height: auto !important;
  font-size: 14px;
  line-height: 35px !important;
}
.btn-group > .btn {
  box-shadow: none;
  &:not(:last-child) {
    margin-right: 1px;
    box-shadow: inset -1px 0 0 rgba(0,0,0,0.2);
  }
}

.btn-group-vertical {
  > .btn:not(.btn-menu) {
    box-shadow: none;
    &:not(:last-child) {
      margin-bottom: 1px;
      box-shadow: inset 0 -1px 0 rgba(0,0,0,0.2);
    }
  }
}

.btn-large {

}
