// Animation styles here

svg * {
  fill: none;
  stroke: currentColor;
  display: block;
  /*color: #076cb9 ;*/
  color: #60b4f5;
}


.icon-bg {
  /*background: #fff;*/
  background-image: url("../../images/icon-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  /*width: 160px;*/
  /*height: 160px;*/
  width: 100%;
  /*border-radius: 50%;*/
}

.line2 { stroke-width:2px; }
.line3 { stroke-width:3px; }
.line4 { stroke-width:4px; }



#object1:hover #chart{
  animation: chart-anim 1s infinite;
}

.imagebackground {
  background:none;
}

.map-new {
  width: 100%;
  height: 200px;
  /*background:red;*/
  margin: 0 auto;
  position: relative;
}

.map-raised-container,
.pin-hero-container,
.pin-hero-shadow-container,
.pin-cover-1-container,
.pin-cover-2-container {
  position: absolute;
  width: 100%;
  top: 0;
}

.pin-hero-container {
  -webkit-animation-delay: 4s;

}

.map-raised {
  position: relative;
  /*width: 900px;*/
  width: 100%;
  height: 200px;
  background-image: url("../../images/map-raised.png");
  /*position: absolute;*/
  bottom: 2px;
  background-position: center;
  background-repeat: no-repeat;

  /*-webkit-animation-duration: 0.6s;*/
  -webkit-animation-delay: 2s;

}

.pin-hero {
  position: relative;
  /*width: 900px;*/
  width: 100%;
  height: 200px;
  background-image: url("../../images/pin-hero.png");
  /*position: absolute;*/
  bottom: 2px;
  left: 4px;
  background-position: center;
  background-repeat: no-repeat;

  /*-webkit-animation-duration: 0.6s;*/
  -webkit-animation-delay: 2.5s;
}

.pin-hero-shadow {
  position: relative;
  /*width: 900px;*/
  width: 100%;
  height: 255px;
  background-image: url("../../images/pin-hero-shadow.png");
  /*position: absolute;*/
  bottom: 2px;
  left: 4px;
  background-position: center;
  background-repeat: no-repeat;

  /*-webkit-animation-duration: 0.6s;*/
  -webkit-animation-delay: 2.5s;
}

.pin-cover-1 {
  position: relative;
  /*width: 900px;*/
  width: 100%;
  height: 225px;
  background-image: url("../../images/pin-cover-1.png");
  /*position: absolute;*/
  bottom: 45px;
  background-position: center;
  background-repeat: no-repeat;

  /*-webkit-animation-duration: 0.3s;*/
  -webkit-animation-delay: 3s;
}


.section-layout-center {
  padding-bottom: 0;
}

.hero-title {
  -webkit-animation-delay: 2.2s;
}

.hero-btn {
  -webkit-animation-delay: 2.2s;
}


@keyframes chart-anim {
  from {
    transform: rotate(0);
    transform-origin: center;
  }

  50% {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(0);
    transform-origin: center;

  }
}

@keyframes pin-shadow-anim {
  from {
    opacity: 1;
    transform: scale(1,1);
    transform-origin: center;

  }

  to {
    opacity: 0;
    transform: scale(2,1.8);
    transform-origin: center;

  }
}

@keyframes pin-meter-anim {
  from {
    transform: rotate(0);
    transform-origin: bottom ;
  }

  30% {
    transform-origin: bottom ;
    transform: rotate(85deg);
  }

  50% {
    transform-origin: bottom ;
    transform: rotate(90deg);
  }

  60% {
    transform-origin: bottom ;
    transform: rotate(85deg);
  }

  70% {
    transform-origin: bottom ;
    transform: rotate(90deg);
  }

  80% {
    transform-origin: bottom ;
    transform: rotate(85deg);
  }


  to {
    transform: rotate(0);
    transform-origin: bottom ;

  }

}


@keyframes steering-anim {

  from {
    transform: rotate(0);
    transform-origin: 50% 50%;
  }

  50% {
    /*transform-origin: center center;*/
    transform: rotate(45deg);
    transform-origin: 55% 50%;

  }

  to {
    /*transform-origin: center center;*/
    transform: rotate(0);
    transform-origin: 50% 50%;
  }

}


@keyframes braking-anim {

  from {
    transform: rotate(0);
    transform-origin: bottom left ;
  }

  8% {
    transform-origin: bottom left ;
    transform: rotate(9deg);
  }

  75% {
    transform-origin: bottom left ;
    transform: rotate(9deg);
  }
  to {
    transform: rotate(0);
    transform-origin: bottom left ;

  }

}




@keyframes braking-pedal-anim {

  from {
    transform: rotate(0);
    transform-origin: center right ;
  }

  8% {
    transform-origin: center right ;
    transform: rotate(-10deg);
  }

  75% {
    transform-origin: center right ;
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(0);
    transform-origin: center right ;

  }

}


#object1:hover #chart{
  animation: chart-anim 1s infinite;
}

#object2:hover #mail,
#object10:hover #mail {
  animation:  fadeInLeft 0.5s;
}

#object2:hover #flicker,
#object10:hover #flicker {
  animation: flash 0.5s infinite;
}

#object3:hover #android {
  animation: flash 0.8s infinite;
}

#object11:hover #android {
  animation: flash 0.8s infinite;
}

#object4:hover #spad {
  animation: flash 0.8s infinite;
}

#object5:hover #pin-shadow {
  animation: pin-shadow-anim 0.8s infinite;
}

#object7:hover #meter-pin {
  animation: pin-meter-anim 0.8s infinite;
}

#object8:hover #steering {
  animation: steering-anim 0.8s infinite;
}

#object9:hover #feet {
  animation: braking-anim 1s infinite;
}

#object9:hover #pedal {
  animation: braking-pedal-anim 1s infinite;
}



