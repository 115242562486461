// Insurance styles here

.insurancelogo {
  background: $pasti-white no-repeat 50% 50%;
  border-radius: 50%;
  width: 300px;
  height: 300px;
}

.half-text-image-text {
  ul {
    padding-left: 30px;
    list-style-image: url('../../images/tick.png');
    li {
      padding-bottom: 20px;
      padding-left: 10px;
    }
  }
}

