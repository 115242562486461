// Modal styles here


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: $screen-sm-min) {
  .modal-container {
    width: 90% !important;
  }
}

label {
  color: $headings-color;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
  font-size: $font-size-h3;
  line-height: 20px;
  border-bottom: 0;
}

textarea {
  resize: vertical; /* user can resize vertically, but width is fixed */
}