// About Us styles here

.aboutustop {
  margin-bottom: 20px
}

.section-layout-myeg-logo {
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 32px;

  h1 {
    color: $pasti-white;
    margin-bottom: 12px;
  }
  img {
    position: absolute;
    top: 50%;
    left : 50%;
    transform: translateX(-50%)translateY(-50%);
  }
  p {
    color: $pasti-white;
  }
}

@media screen and (max-width: $tablet) { 
  .section-layout-myeg-logo {
    text-align: center;
  }
}

.myeg-logo-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.white-myeg {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  background: white;
  position: relative;
  margin: auto;
}

.section-layout-ourteam {
  padding-top: 80px;
  padding-bottom: 80px;
}
