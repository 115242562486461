// Forms styles here

.form-control {
  background-color : $pasti-light-grey;
}


.form-control, .fieldset {
  font-size: 1rem;
  min-width: 100%;
  border-radius: 0px;
  height: 50px;
  padding: $padding-base-vertical 16px;
  box-shadow: none !important;
  border-color: $pasti-light-grey;
  &:focus {
    border-color: $primary-strong-blue;
  }
  &[disabled] {
    color: $dark-grey;
    background-color: $almost-white-grey;
  }
  &[readonly] {
    background-color: $almost-white-grey;
  }
}

.form-control-feedback {
  z-index: 0;
}


.has-success {
  .control-label,
  .help-block,
  .form-control-feedback,
  .checkbox,
  .radio {
    color: $vivid-green;
  }
  .form-control {
    border-color: $vivid-green !important;
    &:focus {
      box-shadow: none;
    }
  }
}

.has-warning {
  .control-label,
  .help-block,
  .form-control-feedback,
  .checkbox,
  .radio {
    color: $vivid-yellow;
  }
  .form-control {
    border-color: $vivid-yellow !important;
    &:focus {
      box-shadow: none;
    }
  }
}
.has-danger {
  .control-label,
  .help-block,
  .form-control-feedback,
  .checkbox,
  .radio {
    color: $vivid-red;
  }
  .form-control {
    border-color: $vivid-red !important;
    &:focus {
      box-shadow: none;
    }
  }
}

.form-control-default,
.form-control-success,
.form-control-warning,
.form-control-danger {
  margin-bottom: 0px;
  padding-bottom: 4px;
  position: relative;
  .fa {
    position: absolute;
    top: 30%;
  }
  &.control-textarea .fa {
    position: absolute;
    top: 16px;
  }
  input {
    height: 50px;
  }
  textarea {
    padding-right: 46px;
    background-color: $pasti-light-grey;
    border-color: $pasti-light-grey;
    box-shadow: none;
    min-height: inherit;
    height: auto;
    border-radius: 0;
    :focus {
      border-color: none !important;
    }
  }
}

 
#example-text-input-focus {
  border-color: $primary-strong-blue;
  box-shadow: none;
}

.form-control-file {
  &::-webkit-file-upload-button {
    border: none;
    border-radius: $border-radius-base;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: $white;
    background-color: $primary-strong-blue;
    box-shadow: inset 0 -4px 0 #006B9D;
  }
  &::-ms-browse {
    border: none;
    border-radius: $border-radius-base;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: $white;
    background-color: $primary-strong-blue;
    box-shadow: inset 0 -4px 0 #006B9D;
  }
}


.form-check {
  .checkbox,
  .radio {
    position: relative;
    padding-left: 30px;
    font-weight: 400;
    > input {
      box-sizing: border-box;
      position: absolute;
      z-index: -1;
      padding: 0;
      opacity: 0;
      margin: 0;
      &:focus ~ .icon {
        outline: 0;
        border: 1px solid $primary-strong-blue;
      }
    }
    > .icon {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 24px;
      height: 24px;
      line-height: 22px;
      text-align: center;
      user-select: none;
      border: 1px solid $input-border;
      color: $primary-strong-blue;
    }
    &:not(.active) > .icon {
      background-color: $white;
    }
    &.active {
      .btn-default { background-color: $white !important; }
    }
    &.disabled,
    &.readonly {
      filter: alpha(opacity=65);
      box-shadow: none;
      opacity: .65;
    }
  }
  .checkbox > .icon {
    border-radius: $border-radius-base;
  }
  .radio > .icon {
    border-radius: 12px;
  }
}

label.btn {
  > input[type="checkbox"],
  > input[type="radio"] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
  }
  &.readonly {
    filter: alpha(opacity=65);
    box-shadow: none;
    opacity: .65;
  }
}
